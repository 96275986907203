import * as React from "react"
import Link from "gatsby-link"

import { graphql } from "gatsby"

import Seo from "../components/seo"

import Row from "../components/themableUI/row"
import Col from "../components/themableUI/col"

export default function Template({ data }) {
  const post = data.markdownRemark

  return (
    <>
      <Seo title={post.frontmatter.title} />

      <Row>
        <h2 className="screen-reader-text">work</h2>

        <Col size={12}>
          <div className="work-post" style={{ marginTop: "30px" }}>
            <h3>{post.frontmatter.title}</h3>
            <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
            <Link to="/work">Go Back</Link>
          </div>
        </Col>
      </Row>
    </>
  )
}

export const postQuery = graphql`
  query WorkPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date
        path
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
